<template>
    <div>
        <Tips/>

        <van-checkbox-group v-model="result" :disabled="isEditing">
          <van-checkbox @click="changeChecked(item)" :name="item.id" v-for="item in cartList" :key="item.id">
              <van-swipe-cell>
                <van-card
                  :num="item.number"
                  :price="item.retail_price"
                  :title="item.goods_name"
                  :thumb="item.list_pic_url"
                />
                <van-stepper @change="stepperChange(item)" v-model="item.number" v-show="isEditing" /> 
                <template #right>
                  <van-button @click="delGood(item.product_id)" square type="danger" text="删除" />
                </template>
              </van-swipe-cell>
          </van-checkbox>
       </van-checkbox-group>


      <van-submit-bar :price="cartTotal.checkedGoodsAmount*100" button-text="提交订单" @submit="onSubmit">
        <van-checkbox v-model="checkedAll">全选</van-checkbox>
        <template #tip>
          累计共<span>{{cartTotal.checkedGoodsCount}}</span>件商品,可点击    
          <van-button @click="editBtn" :type="isEditing?'danger':'primary'" size="small">
            {{isEditing?"完成编辑":"编辑"}}
          </van-button>              
          按钮进行商品数量修理
        </template>
      </van-submit-bar>

    </div>
</template>

<script>

import Tips from '@/components/Tips'
//import {GetCartListData, ChangeGoodChecked, StepperChangeData, DeleteGood} from '@/request/api'
export default {
  data () {
    return {
      // 展示选中的商品的数组
      // 哪一个商品的checked的值为1,就可以把它的id添加到这个数组
      result: [],
      // checkedAll: true,

      // 购物车列表数据
      cartList:[],
      // 商品总信息
      cartTotal:[],
      // 编辑状态的设置
      isEditing: false
    }
  },
 created() {
   GetCartListData().then(res => {
     if(res.data.errno == 0) {
      //  console.log(res.data);
      this.totalFn(res.data.data)

     }
   })
 },
 
  methods: {
    editBtn() {
      this.isEditing = !this.isEditing
    },
    totalFn(data){
      this.cartList = data.cartList
      this.cartTotal = data.cartTotal
      //  console.log(this.cartTotal);
      this.result = []
      this.cartList.map(item => {
         item.checked==1?this.result.push(item.id):""
       })
    },
    onClickEditAddress() {},
    onSubmit() {},
    changeChecked(item) {
      if(this.isEditing)return
      // 总结: 为什么要发送这个请求?这个请求有何作用
      // 这个请求告诉了后端我们改变了哪些值,后端接收之后,返回给我们一个处理过后的完整的页面数据
      // 包括:总结的计算,我们只需要拿到这个数据给页面填写即可
      ChangeGoodChecked({
        isChecked: item.checked==0?1:0,
        productIds:item.product_id,
      })
      .then(res => {
        if(res.data.errno == 0) {
          console.log(res.data);
          this.totalFn(res.data.data)
        }
      })
    },
    stepperChange(item) {
        StepperChangeData({
          goodsId: item.goods_id,
          id: item.id,
          number: item.number,
          productId: item.product_id
        })
        .then(res => {
          if (res.data.errno ==0) {
            console.log(res.data);
            this.totalFn(res.data.data)
          }
        })
    },
    delGood(pid) {
      DeleteGood({productIds: pid.toString()}).then(res => {
        if(res.data.errno == 0) {
          this.totalFn(res.data.data)
        }
      })
    }
  },
   components: {
    Tips
  },
  computed:{
    checkedAll: {
      get() {
        return this.result.length == this.cartList.length
      },
      set(val) { // 即将要修改成的新值
      if(this.isEditing)return

        // 处理id数组
        let ids_arr = [];
        this.cartList.map(item => {
          ids_arr.push(item.product_id)
        })

        ChangeGoodChecked({
            isChecked: val?1:0,
            productIds: ids_arr.join(),
          })
          .then(res => {
            if(res.data.errno == 0) {
              console.log(res.data);
              this.totalFn(res.data.data)
            }
        })
      }

    }
  },
}
</script>
 
<style lang = "less" scoped>
  .van-button--square {
    height: 100%;
  }
  .van-stepper {
    text-align: right;
  }
  /deep/.van-checkbox__label {
    flex: 1;   
  }
  .van-checkbox-group {
    background-color: #fff;  
    padding-bottom: 1.5rem;
    .van-checkbox {
    border-bottom: 1px solid #ccc;
    padding: .1rem .15rem;
    } 
  }
  .van-submit-bar {
    bottom: .5rem;
    border-bottom: 1px solid #ccc;
  }
</style>